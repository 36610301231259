import { i18n } from 'plugins'
import { urlPath } from 'utils'

export const PRODUCTS = [
  {
    title: i18n.t('product') as string,
    children: [
      // {
      //   title: 'Item',
      //   path: urlPath.MASTER_ITEMS.path
      // },
      {
        title: i18n.t('product') as string,
        path: urlPath.MASTER_VARIETIES.path
      },
      {
        title: i18n.t('master.size.title') as string,
        path: urlPath.MASTER_SIZES.path
      },
      {
        title: i18n.t('master.unit.title') as string,
        path: urlPath.MASTER_UNITS.path
      },
      {
        title: i18n.t('master.quality.title') as string,
        path: urlPath.MASTER_QUALITIES.path
      },
      {
        title: i18n.t('master.box_type.title') as string,
        path: urlPath.BOXTYPE_LIST.path
      },
      {
        title: i18n.t('master.quick_input.title') as string,
        path: urlPath.QUICK_INPUT.path
      },
      {
        title: i18n.t('master.nursery_company.title') as string,
        path: urlPath.NURSERY_COMPANY_LIST.path
      }
    ]
  }
]

export const CHILD_GROWER_PRODUCTS = [
  {
    title: i18n.t('product') as string,
    children: [
      {
        title: i18n.t('product') as string,
        path: urlPath.MASTER_VARIETIES.path
      },
      {
        title: i18n.t('master.size.name') as string,
        path: urlPath.MASTER_SIZES.path
      },
      {
        title: i18n.t('master.unit.name') as string,
        path: urlPath.MASTER_UNITS.path
      },
      {
        title: i18n.t('master.quality.name') as string,
        path: urlPath.MASTER_QUALITIES.path
      },
      {
        title: i18n.t('master.box_type.name') as string,
        path: urlPath.BOXTYPE_LIST.path
      }
    ]
  }
]
