

















import { defineComponent, Ref, ref, onMounted } from '@vue/composition-api'
import { SectionHeader } from 'components'
import { constants } from 'utils'
import { PRODUCTS, CHILD_GROWER_PRODUCTS } from './products'

interface SubItem {
  title: string
  path: string
}
interface Item {
  title: string
  children: Array<SubItem>
}
interface SetUp {
  menu: Ref<Array<Item>>
  goToPage: (property: string) => void
}

const Products = defineComponent({
  components: {
    SectionHeader
  },
  props: {},
  setup(props, { root }): SetUp {
    const menu: Ref<Array<Item>> = ref([])
    const { $store } = root

    const getMenuByRole = async (): Promise<void> => {
      const userInfo = await $store.getters.getUserInfo
      const enableAdminMenu = userInfo.memberRole !== constants.memberRole.ROLE_CHILD_GROWER
      menu.value = enableAdminMenu ? PRODUCTS : CHILD_GROWER_PRODUCTS
    }

    const goToPage = (url: string) => {
      if (url !== root.$route.path) {
        root.$router.push({
          path: url,
          query: { called: 'true' }
        })
      }
    }
    onMounted(getMenuByRole)

    return {
      menu,
      goToPage
    }
  }
})

export default Products
